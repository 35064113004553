.wlc-language-selector {
    display: inline-block;
}

.wlc-language-box {
    display: flex;
    position: relative;

    &:hover {

        .wlc-language-list-wrapper {
            display: block;
            visibility: visible;
        }

        .wlc-language-list {
            transform: translateY(0);
            opacity: 1;
            background: map-get($baseColors, 'yellow');
        }

        .wlc-language-box-trigger__arrow {
            transform: rotateX(180deg);
        }
    }
}

.wlc-language-box-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2rem;

    @media (max-width: 400px) {
        margin-right: 1.4rem;
    }

    &:hover {
        text-decoration: none;
    }
}

.wlc-language-box-trigger__lang-code {
    color: map-get($baseColors, 'white');
    font-size: 1.8rem;
    text-transform: uppercase;

    @include media-breakpoint-down(xs) {
        font-size: 1.4rem;
    }
}

.wlc-language-box-trigger__arrow {
    display: block;
    position: absolute;
    width: 14px;
    height: 11px;
    top: 50%;
    margin-top: -5px;
    stroke: map-get($baseColors, 'highlightMain');
    fill: none;
    stroke-width: 2px;
    transform-origin: center;
    transform: rotateZ(0);
    transition: transform 0.4s;
    right: 0;

    @media (max-width: 400px) {
        width: 10px;
    }

    svg {
        display: block;
        max-width: 100%;
        stroke-linecap: round;
        stroke-linejoin: round;

        polyline {
            fill: none;
            stroke: map-get($baseColors, 'white');
        }
    }
}

.wlc-language-list-wrapper {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    visibility: hidden;
    z-index: 1020;

    @media (max-width: 400px) {
        min-width: 100px;
    }
}

.wlc-language-list {
    margin: 5px 0 0;
    padding: 0;
    text-align: left;
    background: map-get($baseColors, 'highlightMain');
    box-shadow: 0 0 0 rgba(0,0,0,0.3);
    transition: .3s all;
    transform: translateY(30px);
    opacity: 0;
    border-radius: 8px;
    overflow: hidden;

    @media (max-width: 400px) {
        margin: 0;
    }
}

.wlc-language-list__item {
    list-style-type: none;

    a {
        padding: 10px;
        display: block;
        color: map-get($baseColors, 'black');
        font-size: 2rem;
        border-bottom: 1px solid #7c7c7c;
        text-decoration: none;

        @include media-breakpoint-down(xs) {
            font-size: 1.4rem;
        }

        &:hover {
            background: lighten(map-get($baseColors, 'yellow'), 10%);
        }
    }

    &:last-child a {
        border: 0;
    }
}

