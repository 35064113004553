.affiliate-about {
    position: relative;
    overflow-x: hidden;
    background:  transparent;
    padding: 0;
    background-image: url("/static/images/background-about.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .affiliate-block-content {

        p {
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &:after {

        @media (max-width: 1500px) {
            transform: skew(-30deg);
        }

        @media (max-width: 1300px) {
            transform: skew(-20deg);
        }

        @include media-breakpoint-down(lg) {
            transform: skew(0deg);
        }
    }

    > .container {
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 5;
    }

    .affiliate-block-content {
        max-width: 978px;
        padding: 10px 0;

        @media (max-width: 1500px) {
            max-width: 900px;
        }

        @media (max-width: 1300px) {
            max-width: 780px;
        }
    }
}
