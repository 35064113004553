.affiliate-header {
    background-color: #151414;
    min-height: 150px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    @include media-breakpoint-down(xs) {
        min-height: 65px;
    }


    @include media-breakpoint-down(md) {
        position: unset;
        top: -1px;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        min-height: 100px;
    }
}

.affiliate-header-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media (max-width: 1237px) {
        // justify-content: center;
        flex-wrap: nowrap;
    }
}

.affiliate-header-logo {
    flex-basis: 135px;
    display: flex;
    justify-content: flex-start;

    @include media-breakpoint-down(md) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @include media-breakpoint-down(sm) {
        display: flex;
        justify-content: flex-start;
        order: unset;
    }

    .logo {
        z-index: 5;
        position: relative;
        display: block;
        align-self: flex-end;
        // max-width: 137px;

        @include media-breakpoint-down(lg) {
            max-width: 150px;
        }

        svg {
            width: 100%;
            height: auto;
        }
    }
}

.affiliate-header-right {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
        order: 2;
    }
}

.affiliate-header-login {
    margin-left: 20px;
    // display: flex;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    padding-right: 10px;

    @include media-breakpoint-down(xs) {
        margin-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
    }

    .btn {
        color: map-get($baseColors, 'white');
        font-size: 16px;
        line-height: unset;
        text-transform: capitalize;
        background-color: transparent;
        border-radius: 5px;
        // margin-right: 10px;
        padding: 6px 38px;

        &:hover {
            color: map-get($baseColors, 'black');
            background-color: map-get($baseColors, 'white');
        }

        @media (hover: hover) {

            &:hover {
                background-color: #fff;
            }
        }

        @include media-breakpoint-down(lg) {
            font-size: 14px;
            padding: 6px 35px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 12px;
        }

        &-login {
            max-width: 120px;
            border: 2px solid #fff;

            @include media-breakpoint-down(md) {
                border: initial;
                font-size: 20px;

                &:hover {
                    background-color: transparent;
                    color: #fff;
                }
            }

            @include media-breakpoint-down(xs) {
                // margin-bottom: 10px;
                margin-right: 0;
                width: 100%;
                padding: 0;
            }
        }

        &-signup {
            padding: 6px 20px;

            @include media-breakpoint-down(md) {
                display: none;
            }


            // @include media-breakpoint-down(xs) {
            //     margin-bottom: 10px;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     width: 100%;
            // }
        }

        &-link {
            color: map-get($baseColors, 'white');
            background-color: #63be07;

            @include media-breakpoint-down(lg) {
                font-size: 14px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 12px;
            }

            &:hover {
                color: map-get($baseColors, 'black');
                text-decoration: none;
                background-color: map-get($baseColors, 'white');
            }
        }

        &-buy-crypto {
            background-color: #dcb103;
            border: 2px solid #dcb103;
            white-space: nowrap;
            display: grid;
            grid-column-start: 2;
            text-transform: none;
            padding: 6px 20px;

            &:hover {
                border: 2px solid #fff;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }
}
