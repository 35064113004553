.affiliate-custom-menu {
    position: absolute;
    top: 70px;
    left: 0;
    width: 42.5%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    background: map-get($baseColors, 'primary');
    padding: 0 15px;

    @include media-breakpoint-down(md) {
        position: unset;
        width: 100%;
        justify-content: center;
        padding: unset;
    }

    &:after {
        content: '';
        position: absolute;
        right: -50px;
        width: 0;
        height: 0;
        border-bottom: 40px solid map-get($baseColors, 'primary');
        border-right: 50px solid transparent;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-right: 40px;

        @include media-breakpoint-down(md) {

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            display: block;
            color: map-get($baseColors, 'white');
            font-size: 17px;
            line-height: 19px;
            text-transform: uppercase;

            @include media-breakpoint-down(lg) {
                font-size: 14px;
            }

            &:hover {
                color: map-get($baseColors, 'yellow');
                transition: all 0.4s;
                text-decoration: none;
            }
        }
    }
}
