.text-buttons {
    display: flex;
    flex-wrap: wrap;

    @include media-breakpoint-down(xs) {
        justify-content: center;
    }

    .btn {
        color: map-get($baseColors, 'black');
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        background-color: map-get($baseColors, 'green');
        padding: 15px 30px;
        margin: 20px 0;

        @include media-breakpoint-down(md) {
            min-width: auto;
            font-size: 16px;
            margin: 10px 0;
        }

        @include media-breakpoint-down(sm) {
            font-size: 14px;
            margin: 5px 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: map-get($baseColors, 'white');
            background-color: map-get($baseColors, 'primary');
        }

        & + .btn {
            background-color: transparent;

            &:hover {
                background-color: #E64330;
            }
        }
    }
}

.wlc-container {
    overflow-x: hidden;
}
