.modal-dialog {
    @include media-breakpoint-down(lg) {
        margin: 3rem auto;
    }

    @include media-breakpoint-down(sm) {
        margin: 3rem;
    }

    @include media-breakpoint-down(xs) {
        margin: 1rem;
    }
}

.modal-content {
    padding: 3rem;
    @include border-radius(0);

    @include media-breakpoint-down(md) {
        padding: 1rem;
    }
}

.modal-header {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    @include border-top-radius(0);

    @include media-breakpoint-down(lg) {
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }

    .modal-title {
        color: #212121;
        font-size: 3rem;
        font-weight: 700;
        line-height: 1;

        @include media-breakpoint-down(md) {
            font-size: 2rem;
        }

        @include media-breakpoint-down(xs) {
            padding-right: 1.5rem;
        }
    }

    .close {
        position: absolute;
        top: -1.8rem;
        right: -1.8rem;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7rem;
        height: 7rem;
        background-color: map-get($baseColors, 'primary');
        border-radius: 50%;
        opacity: 1;
        outline: none;
        @include transition(all .2s);

        @include media-breakpoint-down(lg) {
            width: 6rem;
            height: 6rem;
        }

        @include media-breakpoint-down(xs) {
            position: relative;
            top: -1rem;
            right: -1rem;
            flex-shrink: 0;
            width: 3rem;
            height: 3rem;
        }

        svg {
            fill: none;
            stroke: #ffffff;
            stroke-width: 2px;

            @include media-breakpoint-down(xs) {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        &:hover {
            background-color: darken(map-get($baseColors, 'primary'), 10%);
        }
    }
}

.modal-body {
    color: map-get($baseColors, 'black');
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;

    @include media-breakpoint-down(md) {
        font-size: 1.4rem;
        line-height: 1.2;
    }
}

@include media-breakpoint-up(xl) {
    .modal-xl { max-width: $modal-xl; }
}

@include media-breakpoint-down(lg) {
    .modal-xl { max-width: $modal-lg; }
}

@include media-breakpoint-down(md) {
    .modal-xl { max-width: 720px; }
}

@include media-breakpoint-down(sm) {
    .modal-xl { max-width: 100%; }
}
