.affiliate-promo-menu {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 62px;
    display: flex;
    align-items: center;
    font-weight: bold;

    &__list {
        position: relative;
        padding: 0;
        margin: 0;
        float: none;
        display: flex;
        align-items: center;
        background-color: unset;
        list-style-type: none;
    }

    &.show {

        .affiliate-promo-menu__list {
            display: block;
        }
    }
}

.affiliate-promo-menu__item {
    padding-right: 15px;
    padding-left: 15px;

    @include media-breakpoint-down(xs) {
        padding-left: 5px;
        padding-right: 5px;
    }

    a {
        color: map-get($baseColors, 'white');
        font-size: 18px;
        text-transform: uppercase;
        position: relative;
        height: 36px;
        border: 1px solid transparent;
        transition: 0.25s border ease-in-out;
        border-radius: 5px;
        padding: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;

        @include media-breakpoint-down(lg) {
            font-size: 15px;
        }

        @include media-breakpoint-down(xs) {
            padding: 0 5px;
        }

        &:hover {
            text-decoration: none;
            transition: 0.25s border ease-in-out;
            border: 1px solid #fbb203;
        }
    }
}
