.affiliate-comission {
    overflow: hidden;
    background-color: map-get($baseColors, 'brown');

    @include media-breakpoint-down(lg) {
        background-size: 50%;
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 30px;
    }

    p {
        width: 575px;
        font-size: 20px;

        @include media-breakpoint-down(sm) {
            width: 100%;
            font-size: 12px;
        }
    }

    .text-buttons {
        display: flex;
        justify-content: center;
    }

    &.affiliate-block {

        @include media-breakpoint-down(lg) {
            padding: 40px 0;
        }

        @include media-breakpoint-down(xs) {
            padding: 40px 0;
        }
    }
}


.affiliate-comission-list {
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    grid-auto-rows: 180px;

    p {
        display: none;
    }

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(xs) {
        gap: 12px;
        grid-auto-rows: 145px;
    }

    @media (max-width: 400px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.affiliate-comission-list__item {

    @include media-breakpoint-down(md) {
        max-width: unset;
    }

    @media (max-width: 400px) {
        padding-left: 0;
        height: 100%;
    }

    &--content {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        background-color: map-get($baseColors, 'brightYellow');
        border-radius: 15px;
    }

    .percent {
        color: map-get($baseColors, 'black');
        font-size: 58px;
        font-weight: 600;
        text-align: center;
        border-bottom: 3px solid map-get($baseColors, 'darkYellow');
        margin: 0 25px;
        padding-bottom: 15px;

        @include media-breakpoint-down(xs) {
            margin: 0;
        }

        @media (max-width: 400px) {
            padding-bottom: 0;
            font-size: 40px;
        }
    }

    .sum {
        width: 100%;
        color: map-get($baseColors, 'black');
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        padding-top: 25px;
        font-weight: bold;

        @include media-breakpoint-down(xs) {
            font-size: 16px;
        }

        @media (max-width: 400px) {
            padding: 10px 0;
        }

        @media (max-width: 320px) {
            font-size: 12px;
        }
    }
}
