.affiliate-banner {
    display: flex;
    min-height: 375px;
    background: {
        image: url("/static/images/affiliate-slider-bg.jpg");
        repeat: no-repeat;
        position: center;
        size: cover;
    };
    border-bottom: 15px solid #e8c452;
    border-top: 15px solid #e8c452;
    position: relative;

    &__girl {
        position: absolute;
        width: auto;
        height: 100%;
        right: 0;
        bottom: 0;
        z-index: 0;

        img {
            width: auto;
            height: 100%;
        }
    }


    @include media-breakpoint-down(lg) {
        min-height: 355px;
    }

    @include media-breakpoint-down(xs) {
        background-image: url(/static/images/affiliate-slider-bg-mobile.png);
        min-height: 275px;
        border-top: 0;
        border-bottom: 7px solid #e8c452;
    }

    .text-buttons {
        z-index: 5;

        .btn {
            padding: 8px 30px;
            font-weight: 300;
            background: #63be07;
            color: #151414;
        }
    }

    .container {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
            width: auto;
        }
    }
}

.affiliate-banner-text {
    max-width: 600px;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    font-size: 30px;
    font-weight: bold;
    z-index: 5;

    @include media-breakpoint-down(xs) {
        padding: 0;
        max-width: auto;
    }

    p {
        color: map-get($baseColors, 'white');
        font-size: 40px;
        font-weight: 700;
        line-height: 1.2;
        text-shadow: 0 4px 4px rgba(0,0,0,.75);

        @include media-breakpoint-down(lg) {
            font-size: 25px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }

        @include media-breakpoint-down(xs) {
            text-align: center;
            font-size: 30px;
        }

        &.subtext {
            @include media-breakpoint-down(xs) {
                line-height: 16px;
            }
        }

        span {
            font-size: 40px;
            color: #d0b752;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.75);

            @include media-breakpoint-down(lg) {
                font-size: 25px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }

            @include media-breakpoint-down(xs) {
                text-align: center;
                font-size: 30px;
            }
        }

        small {
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            text-shadow: 0 4px 4px rgba(0, 0, 0, 0.75);

            @include media-breakpoint-down(bg) {
                font-size: 14px;
            }

            @include media-breakpoint-down(xs) {
                font-size: 16px;
            }
        }
    }
}
