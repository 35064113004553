.affiliate-footer {
    flex: 0 0 auto;
    position: relative;
    background-color: #1c1a19;
    border-top: 15px solid #e8c452;
    padding-top: 50px;

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        padding: 30px 0;
    }
}

.affiliate-footer-payments {
    position: relative;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    background-color: #1c1a19;
    border-top: 4px solid #b78807;
    border-bottom: 4px solid #b78807;

    @include media-breakpoint-down(md) {
        padding: 1.5rem 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 1rem 0;
        order: 1;
    }


    &__list {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fit,minmax(0,110px));
        justify-content: space-between;

        svg {
            fill: #e8c452;
            width: 100%;
            max-width: 120px;
            max-height: 35px;
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(auto-fit,minmax(0,80px));
        }
    }
}

.affiliate-footer-contacts {
    grid-area: contacts;

    ul {
        padding: 0;
    }

    li {
        display: flex;
        flex-direction: column;
    }

    &__title {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 5px;
        opacity: .5;
        color: #8493a1;
        font-weight: bold;
    }

    &__value {
        font-size: 14px;
        color: #fbb203;
    }
}

.affiliate-footer-content {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 3.5rem;

    @include media-breakpoint-down(sm) {
        padding-top: 2rem;
        padding-bottom: 2rem;
        order: 3;
    }

    &__column {

        @include media-breakpoint-down(sm) {
            flex-basis: 100%;
        }

        & > .row {
            margin-left: 0;
            margin-right: 0;
            display: grid;
            grid-template-areas: "footer_logo links_legal links_about_us icons copyright copyright copyright copyright";
            gap: 70px;
            align-items: flex-start;

            @include media-breakpoint-down(sm) {
                gap: 10px;
            }
        }
    }
}

.affiliate-footer-merchants {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit,minmax(0,100px));
    justify-content: space-between;

    &-wrapper {
        margin-bottom: 30px;
    }

    @include media-breakpoint-down(sm) {
        order: 2;
    }

    &__item {

        .wlc-svg-block {

            svg {
                fill: #a3949c;
                width: 100%;
                height: auto;

                // .cls-1 {
                //     fill: #fff;
                // }
            }
        }
    }
}

.affiliate-footer-disclaimer {
    color: #616161;
    font-size: 1.4rem;
}

.affiliate-footer-links {
    padding: 0;

    &_legal {
        grid-area: links_legal;
    }

    &_about-us {
        grid-area: links_about_us;
    }
}

.affiliate-footer-links__header {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: 600;

    color: #fbb203;
    border-bottom: 2px solid #fbb203;
    margin-bottom: 20px;

    @media (max-width: 400px) {
        margin-bottom: 0;
    }
}

.affiliate-footer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: icons;

    svg {
        fill: #e8c452;
    }
}

.affiliate-footer-links__list {
    margin: 0;
    padding: 0;
    width: max-content;

    li {
        line-height: 3rem;
        list-style-type: none;
    }

    a {
        display: inline-block;
        color: #fff;
        font-size: 16px;

        @include transition();

        &:hover {
            color: #fff;
        }
    }
}

.affiliate-footer-copyright {
    grid-area: copyright;
    color: #fdf1fe;
    font-size: 10px;

    @include media-breakpoint-down(sm) {
        text-align: center;
        margin-right: 0;
        margin-bottom: 1rem;
    }

    a {
        color: #b6976a;
        border-bottom: transparent 1px solid;
        transition: border-color .4s ease-out;

        &:hover {
            border-color: #8e7145;
        }
    }

    svg {
        fill: #e8c452;
    }
}

.softgamings {
    display: none;
    font-size: 10px;
    color: #009af1;

    @include media-breakpoint-down(xs) {
        flex-grow: 1;
        width: 100%;
    }

    a {
        color: #009af1;
    }
}

@media (max-width: 767px) {

    .affiliate-footer-content__column > .row {
        grid-template-areas:
            "links_legal links_about_us"
            "copyright copyright"
            "icons icons";
    }
}

.affiliate-footer-logo {
    grid-area: footer_logo;
    width: 160px;
    max-width: 160px;
    img {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}
