body,
html,
[data-ui-view],
ui-view {
    line-height: initial;
}

html {
    font-size: 10px;
}

body {
    font-size: 1.4rem;
    background-color: map-get($baseColors, 'background');
    font-family: Roboto, sans-serif;
}

@include media-breakpoint-down(xs) {
    $grid-gutter-width: 20px;
}

.container {

    // @include media-breakpoint-down(md) {
    //     max-width: 900px;
    //     padding-right: 10px;
    //     padding-left: 10px;
    // }
}

.wlc-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > .wlc-content {
        flex: 1 0 auto;
    }
}

.affiliate-block {
    padding: 95px 0;

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }
}

.affiliate-block-heading,
.affiliate-block-content{
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(sm) {
        margin-bottom: 3rem;
    }

    h2 {
        color: map-get($baseColors, 'green');
        font-size: 24px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: unset;

        @include media-breakpoint-down(sm) {
            font-size: 18px;
        }
    }

    p {
        color: map-get($baseColors, 'white');
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        padding-bottom: 70px;
        margin-top: 30px;
        margin-bottom: unset;
        line-height: 24px;



        @include media-breakpoint-down(sm) {
            font-size: 16px;
            margin-top: 10px;
            padding-bottom: 40px;
        }

        @include media-breakpoint-down(xs) {
            padding-bottom: 20px;
        }
    }
}

@font-face {
    font-family: Roboto;
    src: url('/static/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Roboto;
    src: url('/static/fonts/Roboto-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url('/static/fonts/Roboto-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: Roboto;
    src: url('/static/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
