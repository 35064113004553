.affiliate-header-menu {

    &_mobile {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    &_desktop {

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
.affiliate-main-menu {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    @include media-breakpoint-down(md) {
        order: 0;
        margin: 0;
    }

    &__button {
        margin: 0;
        padding: 0;
        background: none;
        border: 0;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        svg {
            fill: none;
            stroke: map-get($baseColors, 'yellow');
            stroke-width: 2px;
        }

        &:after {
            display: none;
        }

        &:focus {
            outline: none;
        }
    }

    &__list {
        position: relative;
        padding: 0;
        margin: 0;
        float: none;
        display: flex;
        align-items: center;
        background-color: unset;

        @include media-breakpoint-down(md) {
            padding: 0;
            display: none;
            position: absolute;
            left: 0;
            margin-top: 20px;
            min-width: 180px;
            background-color: #000;
            border-width: 1px;
        }
    }

    &.show {

        .affiliate-main-menu__list {
            display: block;
        }
    }

    &__item {
        position: relative;
        display: flex;
        padding-right: 10px;
        padding-left: 10px;

        @include media-breakpoint-down(lg) {
            padding-right: 10px;
            padding-left: 10px;
        }

        &:not(:last-child):after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            height: 15px;
            width: 1px;
            top: 30%;
            background-color: map-get($baseColors, 'white');
        }

        a {
            color: map-get($baseColors, 'white');
            position: relative;
            font-size: 16px;
            font-weight: 300;
            text-shadow: unset;
            // padding: 0 15px;
            border: 1px solid transparent;
            border-radius: 5px;
            line-height: unset;
            height: 36px;
            display: flex;
            align-items: center;
            transition: 0.25s border ease-in-out;
            justify-content: flex-start;
            white-space: nowrap;

            @include media-breakpoint-down(lg) {
                padding: 12px;
            }

            @include media-breakpoint-down(md) {
                line-height: 40px;
            }

            // &:after {
            //     height: 2px;
            //     content: "";
            //     display: block;
            //     width: 100%;
            //     height: 3px;
            //     background: #b78807;
            //     position: absolute;
            //     bottom: 0;
            //     opacity: 0;
            //     transition: opacity 0.25s linear, 0.25s color linear;
            // }

            &:hover {
                text-decoration: none;
                // transition: opacity 0.25s linear, 0.25s color linear;
                color: #b78807;

                // &:after {
                //     opacity: 1;
                // }

                // @include media-breakpoint-down(md) {
                //     line-height: 40px;
                // }
            }
        }
    }

    .navbar-toggler-icon {
        width: 25px;
        height: 25px;
    }
}
