.affiliate-why {
    background: map-get($baseColors, 'brown');
    padding: 15px 0;
}

.affiliate-why-list {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 320px) {
        grid-template-columns: 1fr 1fr;
    }
}

.affiliate-why-list__item {
    max-width: 210px;
    width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 15px;
    position: relative;

    @media (max-width: 768px) {
        padding: 0 10px;
        max-width: 100%;
    }

    &:not(:last-child):after {
        content: '';
        position: absolute;
        display: block;
        width: 2px;
        height: 65px;
        background-color: #b78807;
        right: -6px;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: 767px) {
            display: none;
        }
    }

    &:nth-child(3):after {

        @media (max-width: 768px) {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        width: 50%;
        width: 100%;
    }

    @media (max-width: 400px) {
        width: 100%;
        padding: 0;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 62px;
        width: 62px;

        @include media-breakpoint-down(md) {
            height: 105px;
            width: 105px;
        }

        img {
            display: block;
            max-height: 120px;
        }
    }

    .descr {
        margin-top: 10px;
        text-align: center;

        h3 {
            margin-bottom: 5px;
            color: map-get($baseColors, 'white');
            font-size: 16px;
            font-weight: bold;

            @include media-breakpoint-down(md) {
                font-size: 12px;
            }
        }
    }
}
