.affiliate-faq {
    background-color: #141211;
    padding-top: 75px;

    @include media-breakpoint-down(lg) {
        padding: 60px 0;
    }

    @include media-breakpoint-down(sm) {
        padding: 40px 0;
    }

    .affiliate-block-heading {
        margin-bottom: 35px;
    }
}

.affiliate-faq-accordion {
    margin: 0 auto;
    max-width: 830px;
}

.affiliate-faq-accordion__item {
    position: relative;
    margin-bottom: 1rem;
    color: map-get($baseColors, 'black');
    background-color: map-get($baseColors, 'white');
    border: 1px solid map-get($baseColors, 'primary');
    border-radius: 5px;

    .panel-title {
        position: relative;
        margin-bottom: 0;

        @include transition();

        a {
            padding: 3rem 6rem 3rem 3rem;
            display: block;
            color: map-get($baseColors, 'primary');
            font-size: 2rem;
            text-decoration: none;

            @include transition();
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 3rem;
            display: block;
            width: 18px;
            height: 18px;
            margin-top: -9px;
            border-top: 4px solid map-get($baseColors, 'primary');
            border-right: 4px solid map-get($baseColors, 'primary');

            @include transition();

            transform: rotate(45deg);
        }
    }

    &.panel-open {
        .panel-title {
            background-color: solid map-get($baseColors, 'primary');

            a {
                color: solid map-get($baseColors, 'primary');
            }

            &:after {
                border-top-color: #fff;
                border-right-color: #fff;
                transform: rotate(135deg);
            }
        }
    }

    .text {
        padding: 2rem 3rem;
        font-size: 1.4rem;
        line-height: 2.6rem;
    }
}

.affiliate-faq-accordion {

    table {
        width: 100%;
    }

    tr {
        padding-bottom: 1rem;
        display: block;
        user-select: none;
    }

    td {
        display: block;
        color: map-get($baseColors, 'white');
        background-color: map-get($baseColors, 'grey');
        border-radius: 10px;
        overflow: hidden;

        h3 {
            display: block;
            color: map-get($baseColors, 'white');
            font-size: 18px;
            text-decoration: none;
            position: relative;
            @include transition();
            cursor: pointer;
            padding: 10px 15px;
            margin: 0px 15px;
            font-weight: bold;

            @include media-breakpoint-down(sm) {
                padding: 1rem 3rem 1rem 1rem;
                font-size: 16px;
            }

            &:after {
                content: '';
                position: absolute;
                top: 50%;
                right: 15px;
                display: block;
                width: 12px;
                height: 12px;
                border-top: 2px solid map-get($baseColors, 'darkYellow');
                border-right: 2px solid map-get($baseColors, 'darkYellow');
                @include transition();
                transform: rotate(45deg) translate(-60%);

                @include media-breakpoint-down(sm) {
                    right: 1rem;
                }
            }
        }

        h3 + * {
            padding: 0 3rem;
            font-size: 14px;
            line-height: 2.6rem;
            opacity: 0;
            height: 0;
            margin-bottom: 0;
            @include transition();

            @include media-breakpoint-down(sm) {
                padding: 0 1rem;
            }
        }

        &.wlc-show {
            background-color: #fff;
            color: #000;

            h3 {
                color: map-get($baseColors, 'darkYellow');
                border-bottom: 2px solid map-get($baseColors, 'darkYellow');
                font-weight: bold;

                &:after {
                    transform: rotate(135deg) translate(-60%, 60%);
                }
            }

            h3 + * {
                padding: 2rem 3rem;
                display: block !important;
                opacity: 1;
                height: auto;

                @include media-breakpoint-down(sm) {
                    padding: 1rem;
                }
            }
        }
    }
}
